import React from 'react'
import { Box, ListItem, List, Text, useTheme } from '@chakra-ui/react'
import { PageLayout } from '../components/PageLayout'
import { Hero } from '../components/Hero'
import { Link } from '../components/Link'

export const Head = () => (
    <>
        <title>MassBay Community College Website Refresh | Orientier Proposal Hub</title>
        <meta name="description" content="Resources illuminating a proposal to update and improve massbay.edu." />
        <meta name="robots" content="noindex" />
    </>
)

const hero = (
    <Hero
        heading="MassBay.edu Website Refresh"
        subheading="A Proposal by Orientier for Massachusetts Bay Community College"
    />
)

const IndexPage = () => {
    const {
        constants: { LIST_OL_NUMBERED },
    } = useTheme()

    return (
        <PageLayout hero={hero} pageSlug="home">
            <Box maxWidth="container.md" mx="auto" p={8}>
                <Text>
                    Welcome, and thanks for your interest. We hope the resources here will illuminate our proposal.
                </Text>
                <Text>
                    We can transition this space into an interview supplement, discovery platform, and beta testing tool
                    if the opportunity arises.
                </Text>
                <Text>For now, we hope you will consider these three areas:</Text>
                <List variant={LIST_OL_NUMBERED}>
                    <ListItem>
                        We elaborated on some of our relevant <Link destination="/our-experience">experience</Link>
                    </ListItem>
                    <ListItem>
                        We <Link destination="/college-site-review">reviewed</Link> the MassBay.edu site and other
                        higher ed sites for comparison
                    </ListItem>
                    <ListItem>
                        We outlined <Link destination="/project-plan">our plan</Link> to take MassBay.edu to the next level
                    </ListItem>
                </List>
            </Box>
        </PageLayout>
    )
}

export default IndexPage
